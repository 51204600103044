<!-- eslint-disable vue/singleline-html-element-content-newline -->
<template>
  <div>
    <Head title="Договоры" />
    <div class="mb-8 flex max-w-3xl justify-start">
      <h1 class="text-3xl font-bold">Договоры</h1>
    </div>

    <div class="max-w-2xl overflow-hidden rounded bg-white shadow">
      <user-form v-model="form" @submit="submit">
        <div
          class="flex items-stretch border-t border-gray-200 bg-gray-100 px-8 py-4"
        >
          <loading-button class="btn-indigo" type="button" @click="check">
            Найти телефон
          </loading-button>
          <loading-button
            class="btn-danger ml-auto"
            type="button"
            @click="destroy"
          >
            Удалить привязку
          </loading-button>
        </div>
      </user-form>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import UserForm from './Form.vue';

export default {
  components: {
    Head,
    LoadingButton,
    UserForm,
  },
  layout: Layout,
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        account: {
          ...this.account,
        },
      }),
    };
  },
  methods: {
    check() {
      this.form.post(this.$routes.check_accounts(), {});
    },
    destroy() {
      if (confirm('Удалить привязку?')) {
        this.form.delete(this.$routes.accounts(), {
          onSuccess: () => this.form.reset(),
        });
      }
    },
  },
};
</script>
